// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ouEGBmT4{align-items:center;display:flex}.ouEGBmT4>:not(:last-child){margin-right:var(--spacing-sm)}@media(max-width:667.98px){.ouEGBmT4{flex-direction:column}.ouEGBmT4>:not(:last-child){margin-bottom:var(--spacing-s);margin-right:0}.gSB86kvp{width:100%}}.xYvHmClV{min-width:8.67rem}.MbmEr9oA{color:var(--color-primary-on-bg);font-size:.81rem;font-weight:500;line-height:1.38;white-space:nowrap}@media(hover:hover){.MbmEr9oA:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.MbmEr9oA:active{-webkit-text-decoration:underline;text-decoration:underline}}.HFfvZhRm{color:var(--color-error);font-size:.69rem;font-weight:500;padding-top:var(--spacing-xs)}@media(max-width:667.98px){.HFfvZhRm{text-align:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"footer": "ouEGBmT4",
	"btn": "gSB86kvp",
	"submitBtn": "xYvHmClV",
	"agreement": "MbmEr9oA",
	"error": "HFfvZhRm"
};
module.exports = ___CSS_LOADER_EXPORT___;
